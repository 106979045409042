export default class ClientDebounce {
  timeOutId: number

  debounce = (f: Function, time: number) => {
    clearTimeout(this.timeOutId)

    this.timeOutId = setTimeout(f, time)
  }

  clear = () => {
    clearTimeout(this.timeOutId)
  }
}
